/* eslint-disable react/prop-types */
import './index.scss';
//todo: set names for those chunks and manually set media attribute for meta links
// import '../styleguide/layout-s.scss';
// import '../styleguide/layout-m.scss';
// import '../styleguide/layout-l.scss';
// import '../styleguide/layout-xl.scss';
import '../styleguide/layout-responsive.scss';
import styles from './App.module.scss';

import loadable from '@loadable/component';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { setClk } from '../state/clickout/clickout';

import { CONFIG_SHAPE } from '../state/config/config';
import { AppRoutesSwitch } from './AppRoutesSwitch';
import { clipTextEllipsis } from '../utils/textEllipsis';

import FloatingButton from '../components/FloatingButton/FloatingButton';
import DailyBar from '../components/DailyBar/DailyBar';
import { SwitchableFeature } from 'Features/SwitchableFeature/SwitchableFeature';
import { useAuth0Wrapper } from '../utils/auth0';
import { useLocation, useHistory } from 'react-router-dom';
import { parseFeatureFlags } from '../utils/parseFeatureFlags';
import { DAILY_BAR } from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import { CART } from '../features/SwitchableFeature/SwitchableFeature.definitions';
import { useSearchHistoryInit } from '../utils/useSearchHistoryInit';
import { isFeatureEnabled } from '../features/SwitchableFeature/SwitchableFeature';
import { isCookieConsentRequired } from '../utils/isCookieConsentRequired';
import useWishlistUpdater from '../utils/useWishlistUpdater';
import LogoutModal from '../components/LogoutModal';
import useAdultContentInit from '../utils/useAdultContentInit';
import { useAuth0Effects } from '../utils/useAuth0Effects';
import { isMPGTMEnabled } from '../utils/isGTMEnabled';
import dataLayerPush from '../utils/dataLayerPush';

const Gdpr = loadable(() => import('../components/Gdpr/Gdpr'));
const ExpiredSessionModal = loadable(() => import('../components/ExpiredSessionModal'));

const PaymentStatusPopup = loadable(() => import('../components/PaymentStatusPopup/PaymentStatusPopup'));
const NotificationPopup = loadable(() => import('../components/NotificationPopup'));
const CartBootstrap = loadable(() => import('../components/CartBootstrap/CartBootstrap'));
const ProfilePrivacyAgreementPopup = loadable(() => import('../components/ProfilePrivacyAgreementPopup'));

const scrollToTop = () => {
  window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
};

const App = ({ config, setClickoutData }) => {
  const { search, pathname, hash: locationHash } = useLocation();
  const { signupRequired } = useAuth0Wrapper();

  const [cookies, setCookie] = useCookies(['enabledFeatureFlags']);
  const { push } = useHistory();
  useSearchHistoryInit();
  useWishlistUpdater();
  useAdultContentInit();
  useAuth0Effects();

  const paymentIncomplete = locationHash.includes('payment_cancel');
  const paymentSuccess = locationHash.includes('payment_success');
  const processedOrderId = locationHash?.split('+')?.[1];

  if (paymentSuccess && isMPGTMEnabled) {
    const purchaseAttemptProductIds = localStorage.getItem('purchase_attempt_pids');
    const productIds = purchaseAttemptProductIds && JSON.parse(purchaseAttemptProductIds);
    if (productIds?.length) {
      dataLayerPush({ event: 'purchase_success', product_ids: productIds });
      localStorage.removeItem('purchase_attempt_pids');
    }
  }

  const paymentStatusPopupShown = paymentIncomplete || paymentSuccess;

  const closePaymentStatusPopup = () => {
    push({ ...location, hash: '' });
  };

  useEffect(() => {
    //cookie should be set on server side
    if (search) parseFeatureFlags(search, setCookie);
  }, [search, setCookie]);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('load', () => {
      const url = new URLSearchParams(window.location.search);
      const urlClk = url.get('clk');
      const sessionClk = sessionStorage?.getItem('clk');
      const ck = urlClk || sessionClk;

      if (urlClk) sessionStorage.setItem('clk', urlClk);

      if (ck) {
        setClickoutData(ck);
      }
    });
  }, [setClickoutData]);

  const classList = classNames(styles.appRoot, {
    [styles.appRootMarketplace]: isFeatureEnabled(cookies, config, CART),
  });

  return (
    <>
      <Helmet>
        <title>{config?.head?.title}</title>
        <meta name="theme-color" content={config.theme.colors.color_primary} />
        {config.head.meta
          .filter((metaTag) => metaTag.name || metaTag.property)
          .map((metaTag) => (
            <meta key={metaTag.name || metaTag.property} {...metaTag} />
          ))}
        {/* those meta items don't come from config.head.meta, it's fine */}
        <meta property="og:url" content={`${config.baseUrl}/`} />
        <meta name="twitter:site" content={config.social.twitter_account} />
        <meta name="twitter:creator" content={config.social.twitter_account} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content={config.theme.logos.main_logo} />
        <meta name="twitter:title" content={clipTextEllipsis(config.head.title, 70)} />
        <meta property="og:title" content={clipTextEllipsis(config.head.title, 60)} />
        <meta name="twitter:description" content={clipTextEllipsis(config.head.description, 200)} />
        <meta property="og:description" content={clipTextEllipsis(config.head.description, 160)} />
        <link rel="icon" href={config.theme.logos.main_favicon} />
        <link rel="apple-touch-icon" href={config.theme.logos.main_favicon} />
        <link rel="preconnect" href={process.env.REACT_APP_API_V3_ORIGIN} />
        {/** Google Structured Data for search-enabled websites */}
        <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "${config.baseUrl}/",
          "potentialAction": [{
            "@type": "SearchAction",
            "target": "${config.baseUrl}/${config.cmsSlugs.search}/{search_term_string}",
            "query-input": "required name=search_term_string"
          }]
        }`}</script>

        {/** Google Structured Data for the organization */}
        <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${config.baseUrl}/",
          "logo": "https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,w_195,c_scale,fl_lossy/${config.theme.logos.schema_logo}"
        }`}</script>
      </Helmet>
      <div className={classList}>
        {/* todo: drops a couple of points down */}
        {/* todo: request those components on demand, exclude from bandle when feature is disabled */}
        <SwitchableFeature
          dependencies={[
            [DAILY_BAR, true],
            [CART, false],
          ]}
        >
          <DailyBar />
        </SwitchableFeature>
        <AppRoutesSwitch cookies={cookies} config={config} />
        <div className={classNames(styles.float, 'container')}>
          <FloatingButton gap={15} border={true} onClick={scrollToTop}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path
                className={styles.floatingButton}
                fillRule="nonzero"
                d="M4.923 10.91L4 10l.923-.91L14.153 0 16 1.818 7.664 9.972 16 18.182 14.154 20z"
              />
            </svg>
          </FloatingButton>
        </div>
        {signupRequired && <ProfilePrivacyAgreementPopup />}
        <NotificationPopup />
        <ExpiredSessionModal isOpen={false} />
        {paymentStatusPopupShown && (
          <PaymentStatusPopup onClose={closePaymentStatusPopup} success={paymentSuccess} orderId={processedOrderId} />
        )}
        <SwitchableFeature feature={CART}>
          <CartBootstrap />
        </SwitchableFeature>
        <LogoutModal />
        {!!isCookieConsentRequired && <Gdpr />}
      </div>
    </>
  );
};

App.displayName = 'App';
App.propTypes = {
  config: CONFIG_SHAPE,
};

const mapDispatchToProps = (dispatch) => ({
  setClickoutData: (clk) => {
    dispatch(setClk(clk));
  },
});

const mapStateToProps = ({ config, intl, profile }) => ({
  config,
  intl,
  profile,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
