import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';

import { RouteStatus } from '../utils/crossEnvRouter';
import { BLOGS_LIST_PAGE, CART } from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import { getPageRoute } from '../utils/appRoutes';
import { switchableRoute } from 'Features/SwitchableFeature/SwitchableFeature';
import {
  ARD_ALL_CATEGORIES,
  ARD_ALL_SHOPS,
  ARD_BLOGS,
  ARD_BUSINESS,
  ARD_CART_OVERVIEW,
  ARD_CHECKOUT,
  ARD_COOKIES,
  ARD_HOME,
  ARD_LOGOUT,
  ARD_OFFLINE,
  ARD_PRIVACY,
  ARD_PRODUCT,
  ARD_PROFILE,
  ARD_SEARCH,
  ARD_PERMA_SEARCH,
  ARD_SHOP,
  ARD_TERMS_AND_CONDITIONS,
  ARD_ERROR,
  ARD_CATEGORY_SEARCH,
  ARD_BLOG,
  ARD_NOT_FOUND,
  ARD_CATEGORY_CONTENT,
  ARD_CUSTOMER_SERVICE,
  ARD_ORDER_DETAILS,
  ARD_CHAT,
  ARD_CHAT_INIT,
} from '../utils/appRoutes.definitions';
import { COMING_SOON } from '../features/SwitchableFeature/SwitchableFeature.definitions';

const HomePage = loadable(() => import('../pages/HomePage'));
const HomePageMarketplace = loadable(() => import('../pages/HomePageMarketplace'));
const AuthIndicator = loadable(() => import('../components/AuthIndicator'));
//todo: load it only if requested through config
const BlogListPage = loadable(() => import('../components/BlogListPage/BlogListPage'));
const BlogPage = loadable(() => import('../components/BlogPage/BlogPage'));
const BusinessPage = loadable(() => import('../components/BusinessPage/BusinessPage'));
const InfoPage = loadable(() => import('../components/InfoPage/InfoPage'));
const ShopDetailsPage = loadable(() => import('../pages/ShopDetailsPage/ShopDetailsPage'));
const SearchResultsPage = loadable(() => import('../components/SearchResultsPage/SearchResultsPage'));
const CategoryPage = loadable(() => import('../components/CategoryPage/CategoryPage'));
const ProductDetailPage = loadable(() => import('../components/ProductDetailPage'));
const ProductDetailMarketplacePage = loadable(() => import('../components/ProductDetailMarketplacePage'));
const NotFoundPage = loadable(() => import('../components/NotFoundPage/NotFoundPage'));
const ProfilePage = loadable(() => import('../components/ProfilePage'));
const AllShopsPage = loadable(() => import('../components/AllShopsPage/AllShopsPage'));
const AllCategoriesPage = loadable(() => import('../components/AllCategoriesPage/AllCategoriesPage'));
const OfflinePage = loadable(() => import('../components/OfflinePage/OfflinePage'));
const CartOverviewPage = loadable(() => import('../components/CartOverviewPage/CartOverviewPage'));
const CheckoutPage = loadable(() => import('../components/CheckoutPage/CheckoutPage'));
// const ComingSoonPage = loadable(() => import('../components/ComingSoonPage'));
const ComingSoonPage2 = loadable(() => import('../components/ComingSoonPage2'));
const CustomerServicePage = loadable(() => import('../components/CustomerServicePage'));
const OrderDetailsPage = loadable(() => import('../components/OrderDetailsPage'));
const ErrorPage = loadable(() => import('../components/ErrorPage/ErrorPage'));
const POC = loadable(() => import('../components/POC'));

export const AppRoutesSwitch = ({ cookies, config }) => {
  if (switchableRoute(cookies, config, COMING_SOON)) {
    return (
      <Switch>
        <Redirect from="//*" to="/*" />
        <Route exact path={getPageRoute(ARD_HOME)} component={ComingSoonPage2} />
        <Route path={getPageRoute(ARD_PRIVACY)} component={InfoPage} />
        <Route exact path={getPageRoute(ARD_BUSINESS)} component={BusinessPage} />
        <Redirect from="*" to="/" />
      </Switch>
    );
  }
  return (
    <Switch>
      <Redirect from="//*" to="/*" />
      <Route path={getPageRoute(ARD_ERROR)} component={ErrorPage} />
      {switchableRoute(cookies, config, CART) && (
        <Route path={getPageRoute(ARD_CUSTOMER_SERVICE)} component={CustomerServicePage} />
      )}
      {switchableRoute(cookies, config, CART) && (
        <Route path={getPageRoute(ARD_ORDER_DETAILS)} component={OrderDetailsPage} />
      )}
      {switchableRoute(cookies, config, CART) && (
        <Route exact path={getPageRoute(ARD_HOME)} component={HomePageMarketplace} />
      )}
      <Route exact path={getPageRoute(ARD_HOME)} component={HomePage} />
      <Route exact path="/auth-redirect" component={AuthIndicator} />
      <Route path={getPageRoute(ARD_LOGOUT)} render={() => <Redirect to="/" />} />
      <Route
        exact
        path={getPageRoute(ARD_BLOG)}
        render={({ staticContext }) => {
          return <BlogPage staticContext={staticContext} />;
        }}
      />
      {switchableRoute(cookies, config, BLOGS_LIST_PAGE) && (
        <Route exact path={getPageRoute(ARD_BLOGS)} component={BlogListPage} />
      )}
      {switchableRoute(cookies, config, CART) && (
        <Route exact path={getPageRoute(ARD_CART_OVERVIEW)} component={CartOverviewPage} />
      )}
      {switchableRoute(cookies, config, CART) && (
        <Route exact path={getPageRoute(ARD_CHECKOUT)} component={CheckoutPage} />
      )}
      {switchableRoute(cookies, config, CART) && <Route exact path={getPageRoute(ARD_CHAT_INIT)} component={POC} />}
      {switchableRoute(cookies, config, CART) && <Route exact path={getPageRoute(ARD_CHAT)} component={POC} />}
      <Route exact path={getPageRoute(ARD_BUSINESS)} component={BusinessPage} />
      <Route path={getPageRoute(ARD_COOKIES)} component={InfoPage} />
      <Route path={getPageRoute(ARD_PRIVACY)} component={InfoPage} />
      <Route path={getPageRoute(ARD_TERMS_AND_CONDITIONS)} component={InfoPage} exact />
      {switchableRoute(cookies, config, CART) && (
        <Route exact path={getPageRoute(ARD_PRODUCT)} component={ProductDetailMarketplacePage} />
      )}
      <Route
        path={getPageRoute(ARD_PRODUCT)}
        render={({ staticContext }) => {
          return <ProductDetailPage staticContext={staticContext} />;
        }}
      />
      <Route
        path={getPageRoute(ARD_SHOP)}
        render={({ staticContext }) => {
          return <ShopDetailsPage staticContext={staticContext} />;
        }}
      />
      <Route path={getPageRoute(ARD_ALL_SHOPS)} component={AllShopsPage} />
      <Route path={getPageRoute(ARD_ALL_CATEGORIES)} component={AllCategoriesPage} />
      <Route
        path={getPageRoute(ARD_PROFILE)}
        render={() => {
          return <ProfilePage featureFlagsCookie={cookies} />;
        }}
      />
      <Route exact path={getPageRoute(ARD_OFFLINE)} component={OfflinePage} />
      <RouteStatus path={getPageRoute(ARD_NOT_FOUND)} statusCode={404}>
        <NotFoundPage />
      </RouteStatus>
      <Route
        exact
        path={getPageRoute(ARD_PERMA_SEARCH)}
        render={({ staticContext }) => {
          return <SearchResultsPage staticContext={staticContext} />;
        }}
      />
      <Route
        exact
        path={getPageRoute(ARD_SEARCH)}
        render={({ staticContext }) => {
          return <SearchResultsPage staticContext={staticContext} />;
        }}
      />
      <Route
        path={getPageRoute(ARD_CATEGORY_SEARCH)}
        render={({ staticContext }) => {
          return <SearchResultsPage staticContext={staticContext} />;
        }}
      />
      <Route
        exact
        path={getPageRoute(ARD_CATEGORY_CONTENT)}
        render={({ staticContext }) => {
          return <CategoryPage staticContext={staticContext} />;
        }}
      />
      <RouteStatus statusCode={404}>
        <NotFoundPage />
      </RouteStatus>
    </Switch>
  );
};
