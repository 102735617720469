import {
  ARD_HOME,
  ARD_ALL_CATEGORIES,
  ARD_ALL_SHOPS,
  ARD_BLOG,
  ARD_BLOGS,
  ARD_BUSINESS,
  ARD_COOKIES,
  ARD_CART_OVERVIEW,
  ARD_CHECKOUT,
  ARD_LOGOUT,
  ARD_OFFLINE,
  ARD_PRIVACY,
  ARD_PRODUCT,
  ARD_PROFILE,
  ARD_PROFILE_ORDERS_N_RETURNS,
  ARD_PROFILE_WISHLIST,
  ARD_PROFILE_PERSONAL_INFO,
  ARD_PROFILE_NEWSLETTER,
  ARD_PROFILE_BOOKMARKS,
  ARD_PROFILE_RECENT_PRODUCTS,
  ARD_SEARCH,
  ARD_SIGNUP,
  ARD_SHOP,
  ARD_TERMS_AND_CONDITIONS,
  ARD_POPUP_LOGIN,
  ARD_POPUP_PROFILE,
  ARD_POPUP_PROFILE_PERSONAL_INFO,
  ARD_POPUP_PROFILE_ORDERS,
  ARD_POPUP_PROFILE_BOOKMARKS,
  ARD_POPUP_PROFILE_RECENT_PRODUCTS,
  ARD_POPUP_PROFILE_WISHLIST,
  ARD_POPUP_CART,
  ARD_POPUP_PROFILE_NEWSLETTER,
  ARD_PROFILE_ADDRESSES,
  ARD_POPUP_MENU,
  ARD_COMING_SOON,
  ARD_CATEGORY_CONTENT,
  ARD_CATEGORY_SEARCH,
  ARD_NOT_FOUND,
  ARD_CATEGORY_CONTENT_EXPRESS,
  ARD_CATEGORY_SEARCH_EXPRESS,
  ARD_CUSTOMER_SERVICE_ORDER_AND_PAY,
  ARD_CUSTOMER_SERVICE_RETURN_AND_CANCEL,
  ARD_CUSTOMER_SERVICE_WARRANTY_AND_REPAIR,
  ARD_CUSTOMER_SERVICE_DELIVERY,
  ARD_CUSTOMER_SERVICE_USER_ACCOUNT_AND_TERM,
  ARD_CUSTOMER_SERVICE_SHOP_SELLER,
  ARD_CUSTOMER_SERVICE_COMPLAIN,
  ARD_CUSTOMER_SERVICE,
  ARD_ORDER_DETAILS,
  ARD_PROFILE_PERSONALIZATION,
  ARD_PERMA_SEARCH,
  ARD_PROFILE_NOTIFICATION_CENTER,
  ARD_ERROR,
  ARD_POPUP_DAILIES,
  ARD_CHAT,
  ARD_CHAT_INIT,
} from './appRoutes.definitions';

let appRoutes, popups;

export const updateAppRoutes = (cmsSlugs) => {
  if (!cmsSlugs) console.error('Routes update failed');
  popups = {
    [ARD_POPUP_LOGIN]: cmsSlugs.profile,
    [ARD_POPUP_PROFILE]: cmsSlugs.profile,
    [ARD_POPUP_PROFILE_PERSONAL_INFO]: cmsSlugs.profilePersonalInfo,
    [ARD_POPUP_PROFILE_ORDERS]: cmsSlugs.profileOrders,
    [ARD_POPUP_PROFILE_BOOKMARKS]: cmsSlugs.profileBookmarks,
    [ARD_POPUP_PROFILE_RECENT_PRODUCTS]: cmsSlugs.profileRecentProducts,
    [ARD_POPUP_PROFILE_WISHLIST]: cmsSlugs.wishlist,
    [ARD_POPUP_CART]: cmsSlugs.cart,
    [ARD_POPUP_PROFILE_NEWSLETTER]: cmsSlugs.profileNewsletter,
    [ARD_POPUP_MENU]: cmsSlugs.menu,
    [ARD_POPUP_DAILIES]: cmsSlugs.dailies,
  };

  appRoutes = {
    [ARD_HOME]: '/',
    [ARD_ALL_CATEGORIES]: `/${cmsSlugs.allCategories}/`,
    [ARD_ALL_SHOPS]: `/${cmsSlugs.webshops}/`,
    [ARD_BLOG]: '/blog/:slug-([0-9]+)?/',
    [ARD_BLOGS]: `/${cmsSlugs?.blogs}/`, //optional path
    [ARD_BUSINESS]: `/${cmsSlugs.business}/`,
    [ARD_COOKIES]: `/${cmsSlugs.cookies}/`,
    [ARD_CART_OVERVIEW]: `/${cmsSlugs.cartOverview}/`,
    [ARD_CHAT_INIT]: `/${cmsSlugs.chat}/`,
    [ARD_CHAT]: `/${cmsSlugs.chat}/:activeChatId/`,
    [ARD_CHECKOUT]: `/${cmsSlugs.checkout}/`,
    [ARD_LOGOUT]: '/auth/logout/',
    [ARD_OFFLINE]: '/offline/',
    [ARD_PRIVACY]: `/${cmsSlugs.privacy}/`,
    [ARD_PRODUCT]: '/product/:productName/:productId/',
    [ARD_PROFILE]: `/${cmsSlugs.profile}/`,
    [ARD_PROFILE_ORDERS_N_RETURNS]: `/${cmsSlugs.profile}/${cmsSlugs.profileOrdersAndReturns}/`,
    [ARD_PROFILE_WISHLIST]: `/${cmsSlugs.profile}/${cmsSlugs.wishlist}/`,
    [ARD_PROFILE_PERSONAL_INFO]: `/${cmsSlugs.profile}/${cmsSlugs.profilePersonalInfo}/`,
    [ARD_PROFILE_PERSONALIZATION]: `/${cmsSlugs.profile}/${cmsSlugs.profilePersonalization}/`,
    [ARD_PROFILE_NEWSLETTER]: `/${cmsSlugs.profile}/${cmsSlugs.profileNewsletter}/`,
    [ARD_PROFILE_BOOKMARKS]: `/${cmsSlugs.profile}/${cmsSlugs.profileBookmarks}/`,
    [ARD_PROFILE_RECENT_PRODUCTS]: `/${cmsSlugs.profile}/${cmsSlugs.profileRecentProducts}/`,
    [ARD_PROFILE_NOTIFICATION_CENTER]: `/${cmsSlugs.profile}/${cmsSlugs.profileNotificationCenter}/`,
    [ARD_PROFILE_ADDRESSES]: `/${cmsSlugs.profile}/${cmsSlugs.profileAddresses}/`,
    [ARD_SEARCH]: `/${cmsSlugs.search}/`,
    [ARD_PERMA_SEARCH]: `/n/:slug([^/]+)/:uberfilter([0-9\+]+)/`,
    [ARD_SIGNUP]: `/${cmsSlugs.register}/`,
    [ARD_SHOP]: '/shop/:shopWebsite/', //TODO: VERIFY PROPER USAGE
    [ARD_TERMS_AND_CONDITIONS]: `/${cmsSlugs.termsAndConditions}/`,
    [ARD_COMING_SOON]: `/${cmsSlugs.comingSoon}/`,
    [ARD_CATEGORY_CONTENT_EXPRESS]: `/:categoryPath(*)/`, //express 4 compatible route
    [ARD_CATEGORY_SEARCH_EXPRESS]: `/:categoryPath(*)/${cmsSlugs.products}/`, //express 4 compatible route
    [ARD_CATEGORY_CONTENT]: `/:categoryPath(.{0,})/`,
    [ARD_CATEGORY_SEARCH]: `/:categoryPath(.{0,})/${cmsSlugs.products}/`,
    [ARD_NOT_FOUND]: '/404/',
    [ARD_CUSTOMER_SERVICE]: `/support/:page`,
    [ARD_CUSTOMER_SERVICE_ORDER_AND_PAY]: `/support/${cmsSlugs.orderAndPay}/`,
    [ARD_CUSTOMER_SERVICE_RETURN_AND_CANCEL]: `/support/${cmsSlugs.returnAndCancel}/`,
    [ARD_CUSTOMER_SERVICE_WARRANTY_AND_REPAIR]: `/support/${cmsSlugs.warrantyAndRepair}/`,
    [ARD_CUSTOMER_SERVICE_DELIVERY]: `/support/${cmsSlugs.delivery}/`,
    [ARD_CUSTOMER_SERVICE_USER_ACCOUNT_AND_TERM]: `/support/${cmsSlugs.userAccountAndTerm}/`,
    [ARD_CUSTOMER_SERVICE_SHOP_SELLER]: `/support/${cmsSlugs.shopSeller}/`,
    [ARD_CUSTOMER_SERVICE_COMPLAIN]: `/support/${cmsSlugs.complain}/`,
    [ARD_ORDER_DETAILS]: `/${cmsSlugs.orderDetails}/:orderId`,
    [ARD_ERROR]: '/error/',
  };
};

const replacePathParam = (path, param, value) => appRoutes[path].replace(param, value);
const trimSlashes = (param) => param.replace(/^\/|\/$/g, '');

const processCategoriesParam = (categories) => {
  if (Array.isArray(categories) && categories.length) return categories.join('/');
  if (typeof categories === 'string' && categories.trim()) return trimSlashes(categories);
  console.error({ categories });
  throw new Error(`Invalid parameter passed to getPagePath: categories should be non empty string or array.`);
};

const pathBuilderStrategies = {
  [ARD_CATEGORY_CONTENT]: (ARD, categories) =>
    replacePathParam(ARD_CATEGORY_CONTENT, ':categoryPath(.{0,})', processCategoriesParam(categories)),
  [ARD_CATEGORY_SEARCH]: (ARD, categories) =>
    replacePathParam(ARD_CATEGORY_SEARCH, ':categoryPath(.{0,})', processCategoriesParam(categories)),
  [ARD_SEARCH]: (ARD, categories) => {
    const self = pathBuilderStrategies;
    if (!categories || !categories?.length) return self.default(ARD);
    return self[ARD_CATEGORY_SEARCH](ARD, categories);
  },
  [ARD_PERMA_SEARCH]: (ARD, slug, params) => {
    const paramsMap = [
      [':slug([^/]+)', slug],
      [':uberfilter([0-9+]+)', params],
    ];
    return paramsMap.reduce((path, [param, value]) => {
      return path.replace(param, value);
    }, appRoutes[ARD_PERMA_SEARCH]);
  },
  [ARD_ORDER_DETAILS]: (ARD, orderId) => appRoutes[ARD_ORDER_DETAILS].replace(':orderId', orderId),
  [ARD_CHAT]: (ARD, chatId) => {
    if (chatId) return appRoutes[ARD_CHAT].replace(':activeChatId', chatId);
    else return appRoutes[ARD_CHAT_INIT];
  },
  default: (ARD) => {
    const path = appRoutes[ARD];
    if (path) return path;
    throw new Error('Route not found: ' + ARD);
  },
};

export const getPopupOnPagePath = (popupName, pageName) => {
  const page = appRoutes[pageName];
  const popup = popups[popupName];
  const pagePath = page || pageName ? page || `/${pageName}/` : '';
  if (!popup) {
    console.error(`Pathname not found for popup ${popupName}`);
    return `${pagePath}#${popupName}`;
  }
  return `${pagePath}#${popup}`;
};

export const getPageRoute = (name, shouldBeURLEncoded) => {
  const path = appRoutes[name];
  if (path) return shouldBeURLEncoded ? encodeURI(path) : path;
  console.error('Pathname not found: ' + name);
};

export const getExpressPageRoute = (name) => getPageRoute(name, true);

export const getPagePath = (...args) => {
  const ARD = args[0];
  const builder = pathBuilderStrategies[ARD];
  if (builder) return builder(...args);
  return pathBuilderStrategies.default(ARD);
};

export const getPopupName = (popupName) => popups[popupName];

export const getFullPagePath = (...args) => {
  const path = getPagePath(...args);
  if (path) return process.env.REACT_APP_BASE_URL + path;
  console.error('Pathname not found: ' + name);
};

export const isCurrentPath = (args, locationProvider = location) => {
  if (typeof args === 'string') {
    const name = args;
    return trimSlashes(locationProvider.pathname) === trimSlashes(getPagePath(name));
  }
  if (Array.isArray(args)) {
    return trimSlashes(locationProvider.pathname) === trimSlashes(getPagePath(...args));
  } else throw new Error('Invalid params provided');
};

export const isPopupOpened = (name, locationProvider = location) => locationProvider.hash.includes(getPopupName(name));
